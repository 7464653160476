<template>
  <div id="indexlayout">
    <layout-index-left />
    <div id="indexlayout-right" :class="{ 'fiexd-header': siteFiexdHeader }">
      <layout-index-right-top />
      <div class="indexlayout-right-main">
        <div class="main-conent main-conent-minheight">
          <!--
          <el-card shadow="never" class="border-none">
            <el-row
              ><el-button type="primary" @click="removeData"
                >删除</el-button
              ></el-row
            >
          </el-card>-->
          <el-card shadow="never" class="border-none" style="margin-top: 20px">
            <el-row>
              <el-col :span="7">
                <span class="title">项目名称</span>
                <el-cascader
                  v-model="queryform.app"
                  :options="options"
                  @change="handleChange"
                  clearable
                  style="width: 73.2%"
                ></el-cascader>
              </el-col>

              <el-col :span="5">
                <span class="title">姓名</span>
                <el-input
                  class="margin-l10"
                  style="width: 270px"
                  placeholder="请输入姓名"
                  clearable
                  v-model="queryform.name"
                >
                </el-input>
              </el-col>
              <el-col :span="5">
                <span class="title">手机</span>
                <el-input
                  class="margin-l10"
                  style="width: 270px"
                  placeholder="请输入手机"
                  clearable
                  v-model="queryform.phone"
                >
                </el-input>
              </el-col>
              <el-col :span="6">
                <span class="title" style="width: 120px">证件号码</span>
                <el-input
                  class="margin-l10"
                  style="width: 270px"
                  placeholder="请输入证件号码"
                  clearable
                  v-model="queryform.identity"
                >
                </el-input>
              </el-col>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="7">
                <span class="title">开单时间</span>
                <el-date-picker
                  v-model="queryform.daterange"
                  @change="setCreateDateRange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="5">
                <span class="title">开单医生</span>

                <el-select
                  v-model="queryform.doctor"
                  placeholder=""
                  style="width: 73%; margin-left: 10px"
                  clearable=""
                >
                  <el-option
                    v-for="item in doctor_arr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <!--
            <el-col :span="6">
               <span class="title" style="width: 100px">疾病</span>
              <el-select v-model="queryform.id_types" placeholder="" style="width: 270px; margin-left: 10px">
                <el-option v-for="item in disease_arr" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-col>-->
            </el-row>

            <el-row style="margin-top: 20px">
              <el-button type="primary" @click="queryData">查 询</el-button>
            </el-row>
          </el-card>
          <el-card shadow="never" class="border-none" style="margin-top: 30px">
            <div slot="header">
              <el-row>
                <el-col :span="2">
                  <span class="title">查询结果</span>
                </el-col>
                <el-col
                  :span="4"
                  :offset="18"
                  class="text-right"
                  v-if="special_user != '数据查询'"
                >
                  <!--
                <el-button type="primary" @click="showExportDialog">导出数据</el-button>
                <el-button type="primary" @click="showPrintDialog">打印数据</el-button>-->
                </el-col>
              </el-row>
            </div>

            <el-table
              :data="tableData"
              ref="multipleTable"
              stripe
              @selection-change="handleSelectionChange"
            >
            <!--
              <el-table-column
                type="selection"
                width="50"
                :selectable="selectable"
              >
              </el-table-column>-->
              <el-table-column fixed type="index" label="序号" width="50" f>
              </el-table-column>
              <el-table-column fixed prop="app" label="项目名称" width="150">
              </el-table-column>
              <el-table-column fixed prop="create" label="开单时间" width="150">
              </el-table-column>
              <el-table-column fixed prop="name" label="姓名" min-width="100">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <p>姓名: {{ scope.row.name }}</p>
                    <p>电话: {{ scope.row.phone }}</p>
                    <p>住址: {{ scope.row.address }}</p>
                    <div slot="reference" class="name-wrapper">
                      <el-tag size="medium">{{ scope.row.name }}</el-tag>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="age" label="年龄" min-width="80">
              </el-table-column>
              <el-table-column prop="sex" label="性别" min-width="80">
              </el-table-column>
              <el-table-column prop="phone" label="手机" min-width="120">
              </el-table-column>
              <el-table-column prop="identity" label="证件号码" min-width="100">
              </el-table-column>

              <el-table-column prop="address" label="详细地址" min-width="180" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="doctor" label="医生"> </el-table-column>

              <!--
            <el-table-column fixed="right" label="操作" width="180" v-if="special_user!='数据查询'">
              <template slot-scope="scope">
    
                <el-button @click="handleClick(scope.row)" type="text">查看</el-button>
                <el-button @click="tableDel(scope.row)" type="text">删除</el-button>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="180" v-else>
              <template slot-scope="scope">
             
                <el-button @click="handleClick(scope.row)" type="text">查看</el-button>
             
              </template>
            </el-table-column>-->
            </el-table>

            <div class="margin-t16 text-right">
              <el-pagination
                background
                layout="total, prev, pager, next"
                :total="pager.total"
                :page-size="pager.page_size"
                @current-change="pagerChange"
              >
              </el-pagination>
            </div>
          </el-card>

          <!--导出-->
          <el-dialog
            title="导出范围"
            :visible.sync="exportDialogFlag"
            width="20%"
          >
            <el-form ref="exportform" :model="exportform" label-width="80px">
              <el-form-item label="开始页">
                <el-input-number
                  v-model="exportform.start"
                  controls-position="right"
                  @change="changeExportRange"
                  :min="exportform.min"
                  :max="exportform.max"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="结束页">
                <el-input-number
                  v-model="exportform.end"
                  controls-position="right"
                  @change="changeExportRange"
                  :min="exportform.start"
                  :max="exportform.max"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <span style="margin-right: 10px; font-size: 16px"
                >共{{ exportform.max }}页 当前{{ exportform.current }}页</span
              >
              <el-button type="primary" @click="onExport">导出</el-button>
            </span>
          </el-dialog>

          <!--打印-->
          <el-dialog
            title="打印范围"
            :visible.sync="printDialogFlag"
            width="20%"
          >
            <el-form ref="printform" :model="printform" label-width="80px">
              <el-form-item label="开始页">
                <el-input-number
                  v-model="printform.start"
                  controls-position="right"
                  @change="changePrintRange"
                  :min="printform.min"
                  :max="printform.max"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="结束页">
                <el-input-number
                  v-model="printform.end"
                  controls-position="right"
                  @change="changePrintRange"
                  :min="printform.start"
                  :max="printform.max"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <span style="margin-right: 10px; font-size: 16px"
                >共{{ printform.max }}页 当前{{ printform.current }}页</span
              >
              <el-button type="primary" @click="onPrint">打印</el-button>
            </span>
          </el-dialog>

          <!--群发短信-->
          <el-dialog
            title="群发短信范围"
            :visible.sync="smsDialogFlag"
            width="40%"
          >
            <el-form ref="smsform" :model="smsform" label-width="120px">
              <div v-if="multipleSelection.length > 0">
                <el-form-item label="短信接收人列表">
                  <div class="scrolldiv">
                    <el-tag
                      style="margin-right: 15px"
                      :key="tag.id"
                      v-for="tag in multipleSelection"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag)"
                    >
                      {{ tag.name }} {{ tag.phone }}
                    </el-tag>
                  </div>
                </el-form-item>
              </div>
              <el-form-item label="通知日期">
                <el-date-picker
                  v-model="smsform.send_date"
                  type="date"
                  style="width: 90%"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <!--
            <el-form-item label="通知时间段">
              <el-select v-model="smsform.section" style="width:90%">
                <el-option v-for="item in section_options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>-->
              <el-form-item label="通知时间段">
                <el-radio-group v-model="smsform.section">
                  <el-radio label="全天" class="aradio">全天</el-radio>
                  <br />
                  <el-radio class="aradio" label="时间段"
                    >时间区间
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="起始时间段！"
                      placement="top-start"
                    >
                      <el-input-number
                        v-model="astart"
                        :min="6"
                        :max="24"
                        style="margin-left: 40px"
                      ></el-input-number>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="结束时间段！"
                      placement="top-start"
                    >
                      <el-input-number
                        v-model="aend"
                        :min="6"
                        :max="24"
                        style="margin-left: 40px"
                      ></el-input-number>
                    </el-tooltip>
                  </el-radio>
                  <!--
                <br />
                <el-radio class="aradio" label="下午">下午
                  <el-input-number v-model="bstart" :min="12" :max="18" style="margin-left:40px"></el-input-number>
                  <el-input-number v-model="bend" :min="12" :max="18" style="margin-left:40px"></el-input-number>
                </el-radio>
                <br />
                <el-radio class="aradio" label="晚上">晚上
                  <el-input-number v-model="cstart" :min="18" :max="24" style="margin-left:40px"></el-input-number>
                  <el-input-number v-model="cend" :min="18" :max="24" style="margin-left:40px"></el-input-number>
                </el-radio>
                -->
                </el-radio-group>
              </el-form-item>
              <el-form-item label="剩余短信">
                <el-input
                  v-model="last_count"
                  style="width: 90%"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="模板编号">
                <el-select
                  v-model="smsform.template_num"
                  style="width: 90%"
                  @change="changeSmsContent"
                >
                  <el-option
                    v-for="item in sms_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="短信内容">
                <el-input
                  disabled
                  type="textarea"
                  style="width: 90%; height: 4em"
                  v-model="smsform.content"
                  placeholder=""
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div style="margin-left: -100px">
                  注意：①短信内容{1}是指短信“接收人”的姓名，{2}是指“通知日期”+“通知时间段”。请根据实际情况选择通知日期。
                </div>
                <div style="margin-left: -100px">②通知日期应至少提前一天。</div>
                <div style="margin-left: -100px">
                  ③如短信内容有误，请通知技术员变更。
                </div>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <span
                v-if="multipleSelection.length > 0"
                style="margin-right: 10px; font-size: 16px"
                >共{{ multipleSelection.length }}个
              </span>
              <span v-else style="margin-right: 10px; font-size: 16px"
                >共{{ smsform.max }}页 当前{{ smsform.current }}页</span
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="请确保短信剩余量充足！"
                placement="top-start"
              >
                <el-button
                  :disabled="smsform.disabled"
                  type="primary"
                  @click="onSMS"
                  >{{ smstitle }}</el-button
                >
                <!---v-if="smsform.disabled"  -->
              </el-tooltip>
              <el-button @click="exitSMS">取消</el-button>
            </span>
          </el-dialog>

          <!--查看编辑-->
          <el-dialog :visible.sync="editDialogFlag" width="50%">
            <el-form ref="form" :model="form" label-position="right">
              <el-tabs v-model="activeName">
                <el-tab-pane label="个人信息" name="first">
                  <el-row>
                    <el-col :span="10" :offset="1">
                      <el-form-item
                        label="姓名"
                        prop="name"
                        label-width="100px"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.name"
                          autocomplete="off"
                          maxlength="10"
                          placeholder="请输入姓名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="手机"
                        prop="phone"
                        label-width="100px"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.phone"
                          autocomplete="off"
                          maxlength="11"
                          placeholder="请输入手机"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="证件类型"
                        prop="id_types"
                        label-width="100px"
                      >
                        <el-select
                          :disabled="form.role == 'second'"
                          v-model="form.id_types"
                          placeholder=""
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in disease_arr"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        label="证件号码"
                        prop="identity"
                        label-width="100px"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.identity"
                          maxlength="18"
                          class="no-border"
                          placeholder="请输入证件号码"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="工作单位"
                        prop="commpany"
                        label-width="100px"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.commpany"
                          maxlength="18"
                          class="no-border"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                      <el-form-item
                        label="证件户籍"
                        label-width="100px"
                        prop="locations"
                        required
                      >
                        <el-cascader
                          :disabled="form.role == 'second'"
                          :options="regions"
                          v-model="form.locations"
                          @change="handleLocationsChange"
                          style="width: 100%"
                        >
                        </el-cascader>
                      </el-form-item>
                      <el-form-item
                        label="证件地址"
                        prop="id_address"
                        label-width="100px"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.id_address"
                          autocomplete="off"
                          maxlength="100"
                          placeholder="请输入证件地址"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="现居乡镇"
                        prop="sublocations"
                        label-width="100px"
                      >
                        <el-cascader
                          style="width: 100%"
                          v-model="form.sublocations"
                          :options="options2"
                          :props="{ expandTrigger: 'hover' }"
                          @change="handleSubtownChange"
                        ></el-cascader>
                      </el-form-item>
                      <el-form-item
                        label="现居住地"
                        label-width="100px"
                        prop="address"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.address"
                          maxlength="100"
                          class="no-border"
                          placeholder="请输入现居住地的详细地址"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="备注信息"
                        prop="note"
                        label-width="100px"
                      >
                        <el-input
                          :disabled="form.role == 'second'"
                          v-model="form.note"
                          maxlength="18"
                          class="no-border"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    注意：1. 乡镇级管理员仅能修改<span style="color: red"
                      >‘现居乡镇‘</span
                    >的内容！
                  </el-row>
                  <el-row style="margin-top: 10px">
                    2. 乡镇级管理员仅修改'现居乡镇'为<span style="color: red"
                      >'空白'</span
                    >的记录！
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="接种信息" name="second">
                  <el-table :data="manData" stripe style="width: 100%">
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column prop="station" label="接种单位">
                    </el-table-column>
                    <el-table-column prop="medical" label="接种疫苗">
                    </el-table-column>
                    <el-table-column prop="producer" label="生产厂家">
                    </el-table-column>
                    <el-table-column prop="order" label="接种针次">
                    </el-table-column>
                    <el-table-column prop="has_date" label="接种日期">
                      <template slot-scope="scope">
                        {{ scope.row.has_date.slice(0, 10) }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="短信接收列表" name="third">
                  <el-table :data="smsData" stripe style="width: 100%">
                    <el-table-column
                      label="序号"
                      type="index"
                    ></el-table-column>
                    <el-table-column
                      label="发送时间"
                      prop="create"
                    ></el-table-column>
                    <el-table-column
                      label="接收者手机"
                      prop="phone"
                    ></el-table-column>
                    <el-table-column
                      label="短信内容"
                      prop="content"
                      width="400px"
                    ></el-table-column>
                    <el-table-column
                      label="操作者"
                      prop="admin"
                    ></el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </el-form>
            <span
              slot="footer"
              class="dialog-footer"
              v-if="special_user != '数据查询'"
            >
              <el-button @click="editDialogFlag = false">取 消</el-button>
              <el-button
                type="primary"
                v-if="activeName == 'first'"
                @click="saveEditForm()"
                >修改</el-button
              >
            </span>
          </el-dialog>
        </div>
        <layout-index-right-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutIndexLeft from "@/layout/Index/LayoutIndexLeft";
import LayoutIndexRightTop from "@/layout/Index/LayoutIndexRightTop";
import LayoutIndexRightFooter from "@/layout/Index/LayoutIndexRightFooter";
import area from "@/utlis/area.js";
import area2 from "@/utlis/area2.js";
import { getyyyyMMdd, analyzeIDCard } from "@/utlis/tool";
import request from "@/service/lib/request";
import axios from "axios";
import { exportData } from "@/utlis/export";
import printJS from "print-js";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  components: {
    LayoutIndexLeft,
    LayoutIndexRightTop,
    LayoutIndexRightFooter
  },
  data() {
    return {
      doctor_name: "",
      astart: 8,
      aend: 17,
      bstart: 12,
      bend: 18,
      cstart: 18,
      cend: 24,
      smstitle: "群发短信",
      smsFlag: 0,
      smsTimeout: 10,
      regions: regionData,
      activeName: "first",
      radio1: "全部",
      input1: "",
      createDialogFlag: false,
      editDialogFlag: false,
      smsDialogFlag: false,
      options: [
        { label: "全部", value: "" },
        { label: "老年人免费体检", value: 1 },
        { label: "重点人群免费体检", value: 2 },
        { label: "自定义体检项目", value: 3 }
      ],
      options2: area2,
      value: "",
      last_count: 0,
      special_user: "",
      section_options: [
        {
          label: "全天",
          value: "$"
        },
        {
          label: "上午",
          value: "上午"
        },
        {
          label: "下午",
          value: "下午"
        }
      ],
      source_arr: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "人工登记",
          value: 1
        },
        {
          label: "自主登记",
          value: 0
        }
      ],
      sex_arr: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "男性",
          value: 1
        },
        {
          label: "女性",
          value: 0
        }
      ],

      doctor_arr: [],
      disease_arr: [
        {
          label: "全部",
          value: 0
        },
        {
          label: "高血压",
          value: 1
        },
        {
          label: "糖尿病",
          value: 2
        },
        {
          label: "重性精神病",
          value: 3
        },

        {
          label: "脑卒中",
          value: 4
        }
      ],
      form: {
        name: "",
        locations: ["福建省", "宁德市", "霞浦县"],
        sublocations: [""]
      },
      medical_arr: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "新冠疫苗（腺病毒）-1针法",
          value: 1
        },

        {
          label: "新冠疫苗（Vero细胞）-2针法",
          value: 2
        }
      ],
      pager: {
        total: 0,
        page_size: 100,
        page_count: 0,
        current: 0
      },
      exportDialogFlag: false,
      printDialogFlag: false,
      exportform: {
        start: 0,
        end: 0,
        min: 0,
        max: 0,
        current: 0
      },
      printform: {
        start: 0,
        end: 0,
        min: 0,
        max: 0,
        current: 0
      },
      smsform: {
        template_num: "",
        user_list: [],
        content: "",
        send_date: "",
        disabled: false,
        section: "全天",
        radio: ""
      },
      queryform: {
        app: "",
        address: "",
        name: "",
        phone: "",
        identity: "",
        daterange: [],
        adaterange: [],
        bdaterange: [],
        min_create_date: "",
        max_create_date: "",
        doctor: "",
        id_types: "",
        commpany: "",
        count: "",
        medical_type: 2
      },
      tableData: [],
      multipleSelection: [],
      sms_options: [],
      manData: [],
      smsData: []
    };
  },
  computed: {
    ...mapGetters(["siteFiexdHeader"])
  },
  created() {
    // this.initOption();
  },
  methods: {
    init_doctor_arr() {
      request({
        url: "https://scfwzx.3dy.me/api/doctor/",
        method: "get",
        data: { note: this.doctor_name }
      })
        .then((res) => {
          let t = [{ label: "全部", value: "" }];
          for (let i = 0; i < res.data.length; i++) {
            console.log(res.data[i]);
            t.push({ label: res.data[i].note, value: res.data[i].note });
          }
          this.doctor_arr = t;
          console.log("init_doctor_arr", res);

          //this.$message.success('修改成功！');
          // this.editDialogFlag = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    setName() {
      request({
        url: "https://scfwzx.3dy.me/api/doctor/2/",
        method: "put",
        data: { note: this.doctor_name }
      })
        .then((res) => {
          console.log(res);

          this.$message.success("修改成功！");
          this.editDialogFlag = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    removeData() {
      axios
        .get("https://scfwzx.3dy.me/api/removeall/")
        .then((res) => {
          this.$message.success("删除成功！");
          console.log(res);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    selectable(row) {
      //console.log(index);
      if (row.last) {
        return false;
      } else {
        return true;
      }
    },
    initOption() {
      this.special_user = this.$store.getters.name;
      //初始化县镇
      let _region = this.$store.getters.region;
      let region = [];
      if (this.$store.getters.roles.indexOf("admin") > -1) {
        region.push(
          {
            label: "全部",
            value: "全部"
          },
          {
            label: "未知",
            value: "未知"
          }
        );
      }
      for (let i = 0; i < area.length; i++) {
        for (let j = 0; j < _region.length; j++) {
          if (_region[j] === area[i].value) {
            region.push(area[i]);
          }
        }
      }
      if (this.$store.getters.name === "数据查询") {
        region = [
          {
            label: "全部",
            value: "全部"
          }
        ];
      }
      this.options = region;

      //alert(region[0].value);
      console.log("region[0].name", this.$store.getters.name);
      let region2 = [];

      for (let i = 0; i < area.length; i++) {
        for (let j = 0; j < _region.length; j++) {
          if (_region[j] === area[i].value) {
            region2.push({
              value: area[i].value,
              label: area[i].label,
              children: area[i].children.slice(2)
            });
          }
        }
      }

      this.options2 = region2;
    },
    saveEditForm() {
      console.log(
        "thisform",
        this.form,
        this.form.old_subcountry,
        this.form.old_subcountry === null,
        this.form.old_subcountry === "null",
        "########"
      );
      if (this.$store.getters.roles.indexOf("admin") < 0) {
        //this.form.old_subcountry!=='*'||
        if (this.form.old_subcountry !== "*") {
          this.$message.error("禁止修改！");
          return false;
        }
      }

      //this.editDialogFlag = true;
      //this.form.sex=row.sex;
      // this.form.note===""?this.form.note=' ':this.form.note=this.form.note;
      //二级管理员仅允许更改所在镇，
      let data = {};
      let url = "";

      if (this.$store.getters.roles.indexOf("admin") > -1) {
        data = this.form;
        url = "/man/" + this.form.id + "/";
      } else {
        data = {
          id: this.form.id,
          subtown: this.form.sublocations[0],
          subcountry: this.form.sublocations[1]
        };
        url = "/man/update/";
      }
      //alert(url);
      request({
        url: url,
        method: "put",
        data: data
      })
        .then((res) => {
          console.log(res);

          this.$message.success("修改成功！");
          this.editDialogFlag = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    handleSubtownChange(e) {
      this.form.subtown = e[0];
      this.form.subcountry = e[1];
    },
    handleLocationsChange(e) {
      this.form.province = CodeToText[e[0]];
      this.form.city = CodeToText[e[1]];
      this.form.town = e.length === 3 ? CodeToText[e[2]] : "";
    },
    setCreateDateRange(e) {
      // console.log(e);
      if (e != null) {
        this.queryform.min_create_date = getyyyyMMdd(e[0]);
        this.queryform.max_create_date = getyyyyMMdd(e[1]) + " 23:59:59";
       
      } else {
        this.queryform.min_create_date = "";
        this.queryform.max_create_date = "";
      }

      //  console.log(this.queryform.min_create_date, this.queryform.max_create_date);
    },

    handleClick(row) {
      this.editDialogFlag = true;
      this.form = row;
      this.form.old_subcountry = row.subcountry;
      //CodeToText['1222'];
      if (this.$store.getters.roles.indexOf("admin") > -1) {
        this.form.role = "admin";
      } else {
        this.form.role = "second";
      }

      //导入的数据没有省市县
      if (row.province !== null && row.city !== null && row.subtown !== null) {
        this.form.locations = [
          TextToCode[row.province].code,
          TextToCode[row.province][row.city].code,
          TextToCode[row.province][row.city][row.town].code
        ];
        this.form.sublocations = [row.subtown, row.subcountry];
      }

      //查询接种情况
      let birthday =
        row.identity.slice(6, 10) +
        "-" +
        row.identity.slice(10, 12) +
        "-" +
        row.identity.slice(12, 14) +
        "T00:00:00";
      //let identity='';
      //if(new Date(row.create).getTime()<new Date('2021-5-27 16:00:00').getTime()){
      //    identity= row.identity.slice(0, 4) + "*********" + row.identity.slice(row.identity.length - 5, row.identity.length);

      //}else{
      //    identity= row.identity;
      //}
      request({
        url: "/mansample/",
        params: {
          identity: row.identity,
          name: row.name,
          birthday: row.identity.length > 16 ? birthday : ""
        }
      }).then((res) => {
        // console.log("detail ", res);
        // this.manData = res.data;
        let t = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].name === row.name) {
            t.push(res.data[i]);
          }
        }
        this.manData = t;
      });
      // console.log(row);
      //查询接种情况
      request({
        url: "/sms/",
        params: {
          identity: row.identity,
          man: row.name,
          flag: 0
        }
      }).then((res) => {
        // console.log("detail ", res);
        this.smsData = res.data;
      });
    },
    handleChange(e) {
      // alert(e);
      console.log("  handleChange", e);
      this.queryform.app = e[0];
    },
    tableEdit(row) {
      console.log(row);
    },
    tableDel(row) {
      this.$confirm("此操作将永久删除 " + row.name + " , 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          console.log(row);
          request({
            url: "/man/" + row.id + "/",
            method: "delete"
          })
            .then((res) => {
              console.log(res);
              if (res.code === "success") {
                this.$message.success(res.message);
                for (let i = 0; i < this.tableData.length; i++) {
                  if (this.tableData[i].id === row.id) {
                    this.tableData.splice(i);
                    break;
                  }
                }
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {});
    },
    changeSmsRange(e) {
      console.log(e);
    },
    changeSmsContent(e) {
      this.smsform.content = e;
      for (let i = 0; i < this.sms_options.length; i++) {
        if (this.sms_options[i].value === e) {
          this.smsform.template_num = this.sms_options[i].label;
          break;
        }
      }

      console.log("changeSmsContent", e, this.smsform.num);
    },
    sendSMS(row) {
      this.showSmsDialog();
      this.toggleSelection();
      //this.toggleSelection(row);
      this.multipleSelection = [row];
    },
    exitSMS() {
      // this.smsTimeout = 10;
      this.smsform.disabled = false;
      this.smstitle = "群发短信";
      this.smsDialogFlag = false;
      //clearInterval(this.smsFlag);
    },
    onSMS() {
      if (this.multipleSelection.length === 0) {
        this.$message.info("请先选择短信接收人！");
        return false;
      } else {
        this.smsform.user_list = [];
        this.multipleSelection.forEach((val) => {
          this.smsform.user_list.push(val.id);
        });
      }
      if (this.smsform.send_date === "" || this.smsform.send_date == null) {
        this.$message.info("请选择通知日期！");
        return false;
      }
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      if (new Date(this.smsform.send_date).getTime() < today.getTime()) {
        this.$message.error("通知日期错误！请重新选择！");
        //   console.log("this.smsform.send_date)", this.smsform.send_date,new Date(this.smsform.send_date).getTime(),new Date().getTime());
        return false;
      }
      /*
            if (this.smsform.section === '全天') {
                this.$message.info('请选择通知时间段！');
                return false;
            }
            */
      let tmp = "";

      if (this.smsform.section === "时间段") {
        if (this.astart >= this.aend) {
          this.$message.error("起始时间应该小于结束时间！");
          return false;
        }
        tmp = "," + this.astart + "-" + this.aend + "时";
      } else {
        tmp = " 全天";
      } /*else if(this.smsform.section==='下午'){
             if(this.bstart>=this.bend){
              this.$message.error('起始时间应该小于结束时间！');
               return false;
             }
             tmp=this.bstart+'-'+this.bend+":00";
           }else if(this.smsform.section==='晚上'){
              if(this.cstart>=this.cend){
              this.$message.error('起始时间应该小于结束时间！');
               return false;
             }
             tmp=this.cstart+'-'+this.cend+":00";
           }*/

      let data = {
        section: tmp,
        send_date: this.smsform.send_date,
        template_num: this.smsform.template_num.split(" ")[0],
        user_list: this.smsform.user_list
      };

      console.log("this.data", data);

      request({
        url: "/sms/sender/",
        data: data,
        method: "post"
      })
        .then((res) => {
          console.log(res);
          if (res.code === "success") {
            this.$message.success(res.message);
            this.smsDialogFlag = false;
            this.multipleSelection = [];
            this.toggleSelection();
          } else {
            this.$message.error(res.message);
          }
          this.smsform.disabled = false;
        })
        .catch((err) => {
          console.log(err.message);
          this.smsform.disabled = false;
        });
    },

    showSmsDialog() {
      if (this.multipleSelection.length === 0) {
        this.$message.info("请先选择短信接收人！");
        return false;
      }

      //读取短信配置
      request({
        url: "/sms/config/"
      })
        .then((res) => {
          this.last_count = res.data.last_count;
          let sms_options = [];
          res.data.templates.forEach((element) => {
            sms_options.push({
              label:
                element.note !== undefined
                  ? element.num + " " + element.region + " " + element.note
                  : element.num + " " + element.region,
              value: element.content
            });
          });
          this.sms_options = sms_options;
          this.smsform.template_num = this.sms_options[0].label;
          this.smsform.content = this.sms_options[0].value;
          if (res.data.can_send_sms === true) {
            this.smsDialogFlag = true;
          } else {
            this.$message.error("短信功能已禁用！请联系管理员！");
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.smsDialogFlag = true;
        });
      //查找已发送的短信列表
    },
    changeExportRange(e) {
      console.log(e);
    },
    showExportDialog() {
      if (this.tableData.length === 0) {
        return false;
      }
      this.exportDialogFlag = true;
      this.exportform.min = 1;
      this.exportform.max = this.pager.page_count;
      this.exportform.start = this.pager.current_page;
      this.exportform.current = this.pager.current_page;
      this.exportform.end = this.pager.page_count;
    },
    async onExport() {
      if (this.exportform.end - this.exportform.start > 20) {
        this.$message.info("每次导出仅限20页！");
        return false;
      }
      let data = [];
      let posts = await this.query2(this.exportform.start, this.exportform.end);
      for (let i = 0; i < posts.length; i++) {
        if (posts[i]) {
          for (let j in posts[i].data) data.push(posts[i].data[j]);
        }
      }
      console.log("export data", data);
      //printJS(data);

      exportData(data);
    },
    changePrintRange(e) {
      console.log(e);
    },
    showPrintDialog() {
      if (this.tableData.length === 0) {
        return false;
      }

      this.printDialogFlag = true;
      this.printform.min = 1;
      this.printform.max = this.pager.page_count;
      this.printform.start = this.pager.current_page;
      this.printform.current = this.pager.current_page;
      this.printform.end = this.pager.page_count;
    },
    async onPrint() {
      if (this.printform.end - this.printform.start > 20) {
        this.$message.info("每次打印仅限20页！");
        return false;
      }
      let data = [];
      let posts = await this.query2(this.printform.start, this.printform.end);
      for (let i = 0; i < posts.length; i++) {
        if (posts[i]) {
          for (let j in posts[i].data) data.push(posts[i].data[j]);
        }
      }
      //console.log("export data", data);
      let _data = [];
      for (let i = 0; i < data.length; i++) {
        _data.push({
          index: i + 1,
          name: data[i].name,
          identity: data[i].identity,
          subcountry: data[i].subcountry,
          address: data[i].address,
          phone: data[i].phone
        });
      }
      printJS({
        printable: _data,
        properties: [
          {
            field: "index",
            displayName: "序号"
          },
          {
            field: "name",
            displayName: "姓名"
          },
          {
            field: "identity",
            displayName: "证件号码"
          },
          {
            field: "subcountry",
            displayName: "所在村/社区"
          },
          {
            field: "address",
            displayName: "详细地址"
          },
          {
            field: "phone",
            displayName: "电话"
          }
        ],
        type: "json",
        gridStyle:
          "border: 1px solid #000;font-size:14px;padding-left:1em;padding-right:1em",
        gridHeaderStyle: "border: 1px solid #000;font-size:16px;"
      });
    },
    showMsgDialog() {
      let check = false;
      if (check) {
        //如果短信模板不为空.初始化 sms_options
        this.$message.info("请先添加短信模板后再操作！");
        return false;
      }
      this.smsform.num = this.sms_options[0].label;
      this.smsform.content = this.sms_options[0].value;
      this.smsDialogFlag = true;
      this.$message.warning(this.smsform.content + this.smsform.num);
    },
    query2(start, end) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        target: document.querySelector("#activetab"),
        background: "rgba(05, 0, 0, 0.1)"
      });
      // 存储所有http请求
      let reqList = [];
      // 存储后台响应每个请求后的返回结果
      let resList = [];
      for (let i = start; i <= end; i++) {
        let params = {
          subtown:
            this.queryform.subtown === "全部" ? "" : this.queryform.subtown,
          subcountry: this.queryform.subcountry,
          address: this.queryform.address,
          name: this.queryform.name,
          phone: this.queryform.phone,
          identity: this.queryform.identity.toUpperCase(),
          min_create_date: this.queryform.min_create_date,
          max_create_date: this.queryform.max_create_date,
          min_a_date: this.queryform.min_a_date,
          max_a_date: this.queryform.max_a_date,
          min_b_date: this.queryform.min_b_date,
          max_b_date: this.queryform.max_b_date,
          min_birthday: this.queryform.min_birthday,
          max_birthday: this.queryform.max_birthday,
          id_types: this.queryform.id_types,
          commpany: this.queryform.commpany,
          count: this.queryform.doctor,
          source: this.queryform.source,
          sex: this.queryform.sex,
          page: i,
          flag: 0,
          medical_type: this.queryform.medical_type
        };
        let req = request({
          url: "/man",
          params: params
        });
        reqList.push(req);
        resList.push(("post" + (i + 1)).replace(/[']+/g, ""));
      }
      console.log("rquest list:--->", resList);
      return axios.all(reqList).then(
        axios.spread(function (...resList) {
          loading.close();
          return resList;
        })
      );
    },

    query(page) {
      //console.log('this.queryform.daterange', this.queryform.daterange);

      let params = {
        app: this.queryform.app,
        address: this.queryform.address,
        name: this.queryform.name,
        phone: this.queryform.phone,
        identity: this.queryform.identity.toUpperCase(),
        min_create_date: this.queryform.min_create_date,
        max_create_date: this.queryform.max_create_date,
        doctor: this.queryform.doctor,
        page: page
      };
      console.log("params", params);
      request({
        url: "/order",
        method: "get",
        params: params
      })
        .then((res) => {
          console.log(res);
          if (res.total > 0) {
            //this.$message.success('共有'+res.total+"记录！");
            // this.tableData = res.data;
            let t = [];
            for (let i = 0; i < res.data.length; i++) {
              let item = res.data[i];
              item["sex"] = analyzeIDCard(item["identity"]).sex;
              item["age"] = analyzeIDCard(item["identity"]).age;
              t.push(item);
            }
            this.tableData = t;
            this.pager = {
              total: res.total,
              page_size: res.page_size,
              page_count: res.pages,
              current_page: page
            };
            console.log("pager", this.pager);
          } else {
            this.$message.info("暂无记录！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pagerChange(current) {
      console.log(`当前页: ${current}`);
      this.query(current);
    },
    queryData() {
      /*
            if (this.queryform.subtown === '') {
                this.$message.info('请选择所在乡镇！');
                return false;
            }
            let now = new Date();
            if (this.queryform.max_temp.trim() !== '') {
                this.queryform.min_birthday = getyyyyMMdd(
                    new Date(
                        now.getFullYear() - this.queryform.max_temp,
                        11,
                        31
                    )
                );
               
            } else {
                this.queryform.min_birthday = '';
            }
            if (this.queryform.min_temp.trim() !== '') {
                this.queryform.max_birthday = getyyyyMMdd(
                    new Date(now.getFullYear() - this.queryform.min_temp, 0, 1)
                );
                console.log(
                    'this.queryform.min_temp',
                    this.queryform.min_temp,
                    this.queryform.max_birthday
                );
            } else {
                this.queryform.max_birthday = '';
            }
            */
      this.query(1);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleClose(tag) {
      this.multipleSelection.splice(this.multipleSelection.indexOf(tag), 1);
      if (this.multipleSelection.length === 0) {
        this.smsDialogFlag = false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
      let t = [];

      this.multipleSelection.forEach((item, index) => {
        console.log(index);
        t.push({
          label: item.name + " " + item.phone,
          value: item.id
        });
      });
      //if (this.multipleSelection.length > 0)
      // this.smsform.multselect =this.mult_select_options[0].label;
      //this.smsform.multselect = this.multipleSelection[0].name + " " + this.multipleSelection[0].phone;
      this.mult_select_options = t;
    }
  },
  mounted() {
    this.init_doctor_arr();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.red_day {
  color: red;
  font-weight: 700;
}

.scrolldiv {
  height: 4em;
  width: 100%;

  overflow: auto;
}

.title {
  margin-right: 10px;
  width: 110px;
  font-size: 14px;
}

#indexlayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

#indexlayout-right {
  position: relative;
  flex: 1;
  overflow: auto;
  background-color: $mainBgColor;

  &.fiexd-header {
    display: flex;
    flex-direction: column;

    .indexlayout-right-main {
      flex: 1;
      overflow: auto;
    }
  }
}
.aradio {
  width: 500px;
  line-height: 3.2;
}
</style>
